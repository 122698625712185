.shareIcon {
	cursor: pointer;
	position: fixed;
	top: 2%;
	right: 5%;
	z-index: 10;
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.2);
}

@media (min-width: 700px) {
	.shareIcon {
		right: calc(50% - 400px);
	}
}
